import { Suspense, lazy } from 'react';
import { useRoutes, Navigate } from 'react-router-dom';
// components
import LoadingScreen from './components/LoadingScreen';

// ----------------------------------------------------------------------

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <Home />
      ),
    },
    {
      path: 'vip',
      element: (
        <HomeLocal />
      ),
    },
    { path: '*', element: <Navigate to="/" replace /> },
  ])
}

const Home = Loadable(lazy(() => import('./pages/Home')));
const HomeLocal = Loadable(lazy(() => import('./pages/HomeLocal')));
